@import '~bootstrap-css-only/css/bootstrap.min.css';
@import 'assets/styles/Antd/antd.cleanui';
@import 'assets/styles/CleanUI/cleanui';
@import 'assets/styles/Bootstrap/bootstrap.cleanui';
@import 'assets/styles/Chartist/chartist.cleanui';
@import 'assets/styles/Nprogress/nprogress.cleanui';
@import 'assets/styles/Rcdrawer/rcdrawer.cleanui';
@import '~c3/c3.min.css';


p.login-where
{
  color: #777777;		
  font-size: 14px;	
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 5px;
}
.login-head
{
    color: #000000;	
    font-size: 32px;	
    font-weight: bold;
}
.ant-input
{
    height: 52px;
    border: 1px solid #EEEEEE;
}
.ant-input:hover
{
  border: 1px solid #EEEEEE;
}
.ant-input:focus
{
  border: 1px solid #EEEEEE;
}
.ant-form-item
{
    margin-bottom: 8px !important;
}
.ant-form-item-children a{
    color: #777777;
    margin-top: 16px;
    font-weight: 600;
}
.form-actions
{
    border-top: none !important;
}
.form-actions
{
    margin: 0px !important;
}
.form-actions button
{
    height: 56px;
    background: transparent linear-gradient(299deg, #00D182 0%, #2CECA4 100%) 0% 0% no-repeat padding-box !important;
    border-color: #2CECA4 !important; 
}
.form-actions button span
{
    font-size: 16px;
    font-weight: bold;
}
.forgot-head img:before
{
    // content: "\f177";
    content: "\f101";
    font-family: 'Font Awesome 5 Free';
   font-weight: 900;
}
.back-login
{
    position: absolute;
    left: -53px;
    top: 10px;
}
input
{
    font-size: 14px;
}
// .style_navigation__3LZSO
ul.ant-menu
{
    background-color: #0E0E0E !important;
    box-shadow: 6px 0 10px 0 rgba(0,0,0,0.2) !important;
    height: -webkit-fill-available;
}
.ant-menu-item > a
{
    color: #777777;
    font-size: 14px;
}
.ant-menu-item span
{
    color: #777777;
    font-size: 14px;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #222222;
    margin: 0px;
}
.ant-menu-vertical .ant-menu-item::after, .ant-menu-vertical-left .ant-menu-item::after, .ant-menu-vertical-right .ant-menu-item::after, .ant-menu-inline .ant-menu-item::after
{
    border-right: 3px solid #00D182;
}
.ant-menu > .ant-menu-item-divider
{
    background-color: gray;
    opacity: 0.3;
    margin: 0 0px !important;
}
.style_light__VqpV5 .ant-layout-sider-trigger .anticon
{
    font-size: 23px !important; 
}
.ant-layout-sider-trigger
{
   background-color: rgb(0, 0, 0) !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span
{
    color: white !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected span
{
    color: white !important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected:hover span
{
    color: white !important;
}
.ant-menu-item:hover span
{
    color: white !important;
}
.ant-menu-item:active, .ant-menu-submenu-title:active
{
    background: none !important;
}
.ant-menu-submenu span
{
    color: white;
    font-size: 14px;
}
.ant-menu-submenu:hover span
{
    color: white !important;
}
.ant-menu-item
{
    padding-top: 5px !important;
    padding-bottom: 5px !important;
    height: auto !important;
    margin: 0px !important;
}
.ant-menu-item span
{
    transform: translateY(10px);
}
.style_navigation__3LZSO.ant-menu-light .ant-menu-item:hover .style_icon__5TI-s, .style_navigation__3LZSO.ant-menu-light .ant-menu-submenu:hover .style_icon__5TI-s
{
    color: #b8beca;
}
.ant-menu-inline-collapsed .ant-menu-item
{
    height: 50px !important;
}
.ant-layout-sider-trigger i
{
    color: white;
    font-size: 20px;
}
header .mr-auto
{
    width: 100%;
    height: 100%;
}
header .d-inline-block
{
    width: 100%;
    height: 100%;
}
header .d-inline-block .ant-input-affix-wrapper
{
    width: 100% !important;
    height: 100% !important;
}
.ant-layout-header
{
  width: 100%;
  background-color: #4A4A4A;
  border: 1px solid #4A4A4A;
}
.ant-layout-header div
{
    padding-left: 0px !important;
    background-color: #4A4A4A;
    border: 1px solid #4A4A4A;
}
header .ant-input
{
    background-color: #4A4A4A;
    border: 1px solid #4A4A4A;
    border-radius: 0px;
}
.ant-input-affix-wrapper input
{
    border-color: #4A4A4A !important;
}
header .ant-input:hover
{
    border: 1px solid #4A4A4A;
    outline: none;
}
header .ant-input:focus
{
    border: 1px solid #4A4A4A;
    outline: none;
}
header .anticon-search
{
    color: #999999 !important;
    font-size: 22px;
}
header .ant-avatar 
{
    background: #999999;
}
.graph-first
{
    padding: 33px;
    background-color: #313131 !important;
    border-radius: 4px;
    min-height: 455px;
    margin-bottom: 25px;
}
.graph-first svg{
    background-color: #313131 !important;
}
.graph-first h3{
    color: white;
}
.ct-vertical
{
    color: #FFFFFF;
    opacity: 0.4;
    font-size: 11px;
}
.ct-horizontal
{
    color: #FFFFFF;
    opacity: 0.4;
    font-size: 11px;
}
.ant-layout
{
    background-color: #0E0E0E;
}
.ant-layout div span
{
    color: white;
}
.breadcrum1 .style_breadcrumbs__3b4H1
{
    background-color: #0E0E0E;
    border-bottom: none;
    display: none;
}
.utils__content
{
    background-color: #222222;
}
.ant-layout-content
{
    background-color: #222222; 
}
.ant-layout-footer div{
    background-color: #222222; 
}
.ant-layout-footer
{
    background-color: #222222 !important; 
    text-align: center;
    justify-content: center;
    display: flex;
}
.ant-layout-footer p{
    color: #999999;
    font-size: 12px;
}
.breadcrum1 .card
{
    background-color: #313131 !important;
    opacity: 10;
}
.breadcrum1 .card .card-header
{
    background-color: #313131 !important;
    opacity: 10;
}
.breadcrum1 .card .card-header strong
{
    color: white;
    font-size: 18px;
}
.breadcrum1 .card thead th
{
    background-color: #383838;
    border-bottom: 1px solid #212529;
    box-shadow: 0 1px 0 0 rgba(0,0,0,0.5);
}
.breadcrum1 .card tr td{
    background-color: #313131 !important;
    color: white;
    border-bottom: 1px solid gray;
    
}
.ant-table-thead tr th
{
  background-color: #313131 !important;
}
.ant-table-thead tr th:hover
{
  background-color: #313131 !important;
}
.card-header h4
{
    color: white;
    float: left;
}
.card-header p
{
    color: white;
    opacity: 0.4;
}
.page-btn
{
    float: right;
    background-color: #00D182;
    border: 1px solid #00D182;
    width: auto;
    font-size: 14px;
}
.page-btn a{
    color: white !important;
}
.page-btn a:hover
{
    color: white !important;

}
.page-btn:hover
{
    // float: right;
    background-color: #00D182;
    border: 1px solid #00D182;
}
.page-btn1
{
    float: right;
    background-color: #00D182;
    border: 1px solid #00D182;
}
.filter-btn
{
    background: no-repeat;
    width: 65%;
    border: 1px solid #464646;
    text-align: left;
}
.filter-btn:hover
{
    background: no-repeat;
}
.filter-btn i
{
    float: right;
    transform: translateY(5px) !important;
    color: white;
}
.ant-dropdown-menu
{
  background-color: #313131 !important; 
  border: 1px solid #464646 !important;
}
.ant-dropdown-menu li
{
    color: white;
    background-color: inherit;
}
.ant-dropdown-menu li:hover
{
  background-color: #313131 !important;
}
.filter-btn1
{
    background: no-repeat;
    width: 27%;
    border: 1px solid #464646;
    text-align: left; 
}
.filter-btn1:hover
{
    background: no-repeat;
}
.filter-btn1 i
{
    float: right;
    transform: translateY(5px) !important;
    color: white;
}
.ant-menu-item-selected a:before
{
    border-left: 3px solid #00D182;
}
.ant-menu-item-selected a:visited
{
    border-left: 3px solid #00D182;
}

.active
{
    border-left: 3px solid #00D182 !important;
    background: green !important; 
}
.ant-menu-sub
{
    background-color: #0E0E0E !important;
}
.add-genre-heading
{
  color: white;
}
.genre-form
{
    text-align: center;

    justify-content: center;
}
.genre-form p
{
   color: #FFFFFF;
   opacity: 0.4;
}
.genre-form input
{
    background: no-repeat;
    border: 1px solid #464646;
    border-radius: 4px;
    margin-top: 10px;
    color: white;
}
.genre-form textarea
{
    background: no-repeat;
    border: 1px solid #464646;
    border-radius: 4px;
    margin-top: 10px;
    color: white;
}
.genre-form textarea
{
    background: no-repeat;
    border: 1px solid #464646;
    border-radius: 4px;
    margin-top: 10px;
    min-height: 70px !important;
}
.genre-form .ant-select-selection
{
    background: no-repeat !important;
    border: 1px solid #464646;
    border-radius: 4px;
    margin-top: 10px;
    height: 52px;
    width: 100%;
    color: white;
}
.genre-form .ant-select
{
    width: 100%;
}
.genre-form .ant-select .ant-select-selection__rendered
{
    height: 52px;
}
.ant-select-dropdown
{
    background: #0E0E0E !important;
    border: 1px solid #0E0E0E !important;
}
.ant-select-dropdown ul li,
.ant-select-dropdown-menu-item-group-title
{
    color: white !important;
}
.ant-select-dropdown ul li:hover
{
    background: no-repeat !important;
}
.ant-select-selection-selected-value
{
    transform: translateY(10px);
}
.ant-select-dropdown-menu-item-selected
{
    background: no-repeat !important;
}
.genre-cancel
{
    background-color: #4A4A4A;
    border: 1px solid #464646;
    color: #999999;
    width: 45%;
    float: left;
    margin-right: 25px;
    padding-top: 15px;
    padding-bottom: 38px;
    border-radius: 4px;
    font-size: 14px;
}
.genre-cancel:hover
{
    background-color: #4A4A4A;
    border: 1px solid #464646;
}
.genre-add
{
    background: #00D182;
    width: 45%;
    float: right;
    padding-top: 15px;
    padding-bottom: 38px;
    border: 1px solid #00D182;
    border-radius: 4px;
    font-size: 14px;
}
.genre-add:hover
{
    background: #00D182;
    border: 1px solid #00D182;
}
.genre-add:focus
{
    background: #00D182;
    border: 1px solid #00D182;
}
.add-genre-card
{
    padding-top: 9%;
    padding-bottom: 10%;
}
.add-genre-card .icmn-cross
{
    justify-content: end;
    display: grid;
    transform: translate(-80px, -80px);
    color: gray;
    font-size: 15px;
}
.genre-form1 button
{
    width: 46%;
    height: 20% !important;
}
.ant-select-selection
{
    background-color: #313131 !important;
    border: 1px solid gray !important;
}
.ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled)
{
    background: no-repeat !important;
}
.ant-pagination-item
{
    background-color: #313131 !important;
    border: 1px solid gray !important;
    color: white !important;
}
.ant-pagination-item-link
{
    background-color: #313131 !important;
    border: 1px solid gray !important;
    color: white !important;  
}
.ant-select-selection__rendered .ant-select-selection-selected-value
{
   transform: translateY(0px);
   color: white !important;
}
.ck.ck-toolbar
{
    background-color: #313131 !important;
    border: 1px solid gray !important;
    color: white;
}
.ck.ck-editor__main>.ck-editor__editable
{
    background: transparent !important;
}
.ck.ck-button, a.ck.ck-button
{
    color: white !important;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) 
{
    border: 1px solid #464646;
}
.ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) p
{
  color: white !important;
  opacity: 10 !important;
}
.ant-spin-nested-loading .ant-table-placeholder
{
    background: transparent;
    border: 1px solid #464646;
    // display: none;
}
.auditiondetail-lebelinformation-table .ant-spin-nested-loading .ant-table-placeholder 
{
 display: none !important;
}
.ant-spin-nested-loading .ant-table-placeholder .ant-empty-normal
{
    color: white;
}
.genre-cancel:hover
{
    color: #999999 !important;
}
.ant-layout-sider-children div
{
    background: #0E0E0E;
}
.ant-dropdown-menu-item a

{
    color: white;
}
.style_breadcrumbs__2KXsp{
    background: round;
    border-bottom:none
}
.react-waves
{
    padding: 0px 15px 0 40px !important;
    margin: -24px 0px 0px 50px !important;
}
.react-waves wave{
height: 40px !important;
}
// .play.button
// {
//     background: #00D182;
//     width: 76px;
//     padding: 3px;
//     text-align: center;
//     border-radius: 4px;
//     cursor: pointer;
// }
.remark-modal .ant-modal-footer
{
  display: none;
}
.remark-modal .ant-modal-body button
{
    float: none;
}
.ant-table-fixed img{
    max-width: 70px;
}
.ant-tabs-nav .ant-tabs-tab
{
    color: white !important;
    font-weight: bold;
    background: transparent !important;
}
.ant-tabs-nav .ant-tabs-tab-active
{
    color: #00D182 !important;
    font-weight: bold !important;
    background: transparent !important;
    border-bottom: 1px solid #313131 !important;
}
.ant-table-row-selected td {
    color: black !important;
}
.ant-tabs-ink-bar
{
  background-color: #00D182 !important;
}
textarea
{
    background: transparent !important;
}
.ant-layout div span {
    background: transparent;
    
}
input
{
    background: transparent !important;
}
.ant-calendar-picker
{
    width: 100% !important;
}
.ant-calendar-picker-icon
{
    color: white;
}
.ant-btn-primary
{
    background: #00D182 !important;
    border-color: #00D182 !important;
}
.ant-upload-select-picture
{
    width: 100% !important;
}
.ant-upload-select-picture .ant-btn
{
    width: 100% !important;
    background: transparent !important;
}
.ant-modal-body table thead th
{
   background: transparent !important;
   font-weight: bold;
}
.ant-modal-body table thead th:hover
{
   background: transparent !important;
}
.medal-modal .ant-modal-footer
{
    display: none;
}

input
{
    color: white !important;
}
.ant-upload.ant-upload-select
{
  width: 100% !important;
}
.ant-upload.ant-upload-select .ant-btn
{
  width: 100% !important;
  background: transparent !important;
}
.ant-upload.ant-upload-select-picture-card
{
    background: transparent !important;
}
.ant-modal-footer
{
    display: none;
}
.disable-cancel
{
float: none;
}
input:not(.whiteText)
{
    color: black !important;
}
label{
    color: white !important;
}
form .ant-pagination-item-link ,a
{
    margin: 0px !important;
}
.ant-upload-list.ant-upload-list-picture-card
{
    position: absolute;
}
.card form input
{
    color: white !important;
}
.ant-select-selection
{
  background: transparent !important;
//   height: 52px !important;
  border: 1px solid #EEEEEE !important;
  color: black !important;
}
.ant-select-selection__rendered .ant-select-selection-selected-value
{
 color: black !important;
}
.white .ant-select-selection__rendered .ant-select-selection-selected-value
{
 color: white !important;
}

.ant-modal .ant-checkbox-wrapper
{
    color: black !important;
}
table td
{
    word-wrap: unset;
    white-space: normal;
}
.trackdetail-label-table table tbody td
{
    font-size: 18px;
    color: white !important;
}
.trackdetail-label-table table thead th
{
    background: transparent !important;
    border-bottom: none !important;
    box-shadow: none !important;
}
.trackdetail-label-table.card button
{
    float: left;
}
.trackdetail-label-table.card
{
    background: transparent !important;
}
.trackdetail-label-table.card table tbody td
{
    background: transparent !important;
    border-bottom: none !important;
}
.trackdetail-label-table.card table tbody td p
{
    margin: 0px;
}
.trackdetail-label-table table tbody tr:hover
{
  background: transparent !important;
}
.label-img
{
    margin-top: -45px;
}
.trackdetail-label-table .ant-pagination
{
    display: none;
}
.track-edit .ant-form-item {
    margin-left: 15px;
    float: left;
}
.track-edit .tag-formitem .ant-form-item
{
    width: 25% !important;
    height: 2em;
}
.track-edit-modal .ant-form-item 
{
    width: 47%;
    margin-left: 15px;
    float: left;
}
.tag-formitem .ant-form-item
{
    width: 30% !important;
}
.track-edit-modal table tr{
    float: left;
}
.checkbox-form
{
    width: 100% !important;
}
.checkbox-form tr td label
{
  color: black !important;
}
.ant-form-item-label label
{
    color: black !important;
}
.white .ant-form-item-label label
{
    color: white !important;
}
.tag-formitem
{
    width: 100% !important;
}
.track-detail-page .ant-pagination
{
    display: none;
}
.tag-formitem .ant-form-item
{
    margin: 0px !important;
}
// .tag-formitem .ant-form-item-children
// {
//     display: block;
//     position: relative;
  
//     // cursor: pointer;
    
//     // -webkit-user-select: none;
//     // -moz-user-select: none;
//     // -ms-user-select: none;
//     // user-select: none;
// }
// .tag-formitem .ant-form-item-children input
// {
//     position: absolute;
//     opacity: 1;
//     cursor: pointer;
//     height: 25px;
//     width: 25px;
// }
// .tag-formitem .ant-form-item-children .checkmark {
//     position: relative;
//     top: 0;
//     left: 0;
//     height: 25px;
//     width: 25px;
//     background-color: #eee;
//     border-radius: 3px;
//   }
//   .tag-formitem .ant-form-item-children input:checked ~ .checkmark {
//     background-color: #00D182;
//     border-radius: 3px;
//   }
//   .checkmark:after {
//     content: "";
//     position: absolute;
//      display: none;
//   }
//   .tag-formitem .ant-form-item-children input:checked ~ .checkmark:after {
//     display: block;
//   }
//   .tag-formitem .ant-form-item-children .checkmark:after {
//     left: 9px;
//     top: 5px;
//     width: 5px;
//     height: 10px;
//     border: solid white;
//     border-width: 0 3px 3px 0;
//     -webkit-transform: rotate(45deg);
//     -ms-transform: rotate(45deg);
//     transform: rotate(45deg);
//   }
  .tag-name
  {
      margin-left: 30px;
  }
  .track-edit-modal input
  {
      height: 32px !important;
  }
  .labelinformation-table .ant-table-pagination
  {
      display: none;
  }
  .labeldetailpage-image label
  {
      color: white !important;
  }
  .trackdetail-label-table img
  {
     margin-top: -55px !important;
  }
  .label-info-form label
  {
      color: white !important;
  }
  .toggle
  {
      width: 35% !important;
      float: left !important;
  }
  .ant-switch-checked
  {
      background: #00D182 !important;
  }
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::scrollbar {
    width: 8px;
  }
   
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
  }
  ::scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.5);
  }
   
  ::-webkit-scrollbar-thumb {
    background: #2C2C2C 0% 0% no-repeat padding-box;
  }
  ::scrollbar-thumb {
    background: #2C2C2C 0% 0% no-repeat padding-box;
  }
  
  .approvestatus-table .ant-table-pagination
{
  display: none;
}
.approvestatus-table thead
{
    display: none;
}
.approvestatus-table tbody td
{
    border-bottom: none !important;
}
.labelinformation-table .ant-table-pagination
{
  display: none;
}
.ant-btn[disabled]
{
    background: #00D182 !important;
    border: 1px solid #00D182 !important;
}
// .trackdetail-table thead
// {
//     display: none;
// }
.trackdetail-producer-table tbody td{
    font-weight: 600;
    font-size: 18px;
}
.trackdetail-producer-table .ant-table-pagination
{
    display: none !important;
}
.status-btn button
{
    background-color: transparent !important;
    border: none !important;
}
.trackfile-labeldetail .ant-table-pagination
{
    display: none;
}
.trackfile-labeldetail tbody td
{
    border-bottom: none !important;
}
.trackdetail-label-table .card-header
{
    background: transparent !important;
}
.edit-disable-labelinformation .ant-table-pagination
{
   display: none;
}
.producerdetail-table .ant-table-pagination
{
    display: none;
}
.page-btn:active , .page-btn:focus
{
    background: #00D182 !important;
    border: 1px solid #00D182 !important;
}
.track-detail-page thead
{
    display: none !important;
}
.track-detail-page tbody td
{
    border-bottom: none !important;
}
.track-detail-page td
{
    cursor: auto !important;
}
.track-detail-page button
{
    cursor: auto !important;
}
.track-detail-page tbody tr td:nth-child(1) 
{
// width: 90px;
//    padding-top: 0px !important;
padding-left: 0px !important;
padding-right: 0px !important;
}
.tracktable-auditiondetail table tr:nth-child(2) td:nth-child(1)
{
    display: none !important;
}
.tracktable-auditiondetail table tr:nth-child(2) td:nth-child(2)
{
  
    padding: 12px 0px !important;
    border-bottom: none !important;
}
.tracktable-auditiondetail table tr:nth-child(2) td:nth-child(2) p
{
    width: 130%;

}
.tracktable-auditiondetail table .tracktable-auditiondetail1 table tr td:nth-child(1)
{
    display: block !important;
    padding: 19px 8px !important;
    border-bottom: 1px solid #464646 !important;
  
}
.tracktable-auditiondetail table .tracktable-auditiondetail1 table tr td:nth-child(2)
{
   width: auto !important;
   border-bottom: 1px solid #464646 !important;
  
}


// ul li:nth-child(3) 
// {
//     background: #00D182 !important;
//     border-left: 4px solid #00D182 !important;
// }
.admin_check {
    font-size: 1.4em;
}
.admin_check .fa-check.checked {
    color: green;
}
.admin_check .fa-flag.checked {
    color: red;
}
.admin_check .fa-sun-o.checked {
    color: yellow;
}
.track-detail-page tbody tr td:nth-child(2) 
{
   padding-top: 0px !important;
}
.trackname-trackdetail
{
    font-size: 24px;
    color: white;
}
.white
{
    color: white;
}

.sold-alert .ant-alert-message span
{
    color: black;
}
.labelname-tackdetail
{
    font-size: 20px;
    margin-top: -9px;
    margin-bottom: -4px;
}
.track-detail-page img{
    border-radius: 4px;
}
.labelname-head
{
    color: #777777;
    font-size: 14px;
    font-weight: 600;
}
.date-head
{
    color: #777777;
    float: left;
    margin: 0px;
    margin-right: 5px;
}
.hotpic-btn
{
    margin-top: 0px !important;
}
.trackdetaillabel-table thead th
{
    padding-bottom: 0px !important;
    border-bottom: none !important;
}
.trackdetaillabel-table thead th span
{
    color: #777777 !important;
    font-size: 14px;
    font-weight: 600;
}
.trackdetaillabel-table tbody td{
    border: none !important;
    font-size: 16px;
    padding-top: 0px !important;
}
.trackdetaillabel-table .ant-table-pagination
{
    display: none;
}
.advanceinformation-table .ant-table-pagination
{
    display: none;
}
.advanceinformation-table tbody td
{
    border-bottom: none !important;
}
.description-table thead th
{
    padding-bottom: 0px !important;
    border: none !important;
}
.description-table thead th span
{
    font-size: 18px;
    font-weight: bold;
}
.description-table tbody td
{
    border-bottom: none !important;
}
.description-table .ant-table-pagination
{
    display: none !important;
}
.ant-checkbox-checked .ant-checkbox-inner
{
    background: #00D182 !important;
    border: 1px solid #00D182 !important;
}
.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner
{
    border-color: #00D182 !important;
}
.vocal-pack-table tbody td
{
   border-bottom:  1px solid #464646 !important;
}
.vocal-pack-table .ant-table-pagination
{
    display: none;
}
.disapprove-reason-table tbody td
{
    border: none !important;
}
.disapprove-reason-table thead th
{
    border-bottom: none !important;
}
.disapprove-reason-table .ant-table-pagination
{
    display: none;
}
.disapprove-reason-table th span
{
    font-weight: 600;
    font-size: 14px;
    color: #777777 !important;
}
.disapprove-reason-table tbody td
{
    font-size: 18px;
    font-weight: bold;
}
.trackdetail-btn
{
    border: 1px solid #464646 !important;	
    background-color: #4A4A4A !important;
    color: #999999 !important;
    font-size: 14px;
}
.trackdetail-btn:active
{
    border: 1px solid #464646 !important;	
    background-color: #4A4A4A !important;
    color: #999999 !important;
    font-size: 14px;
}
.trackdetail-btn:focus
{
    border: 1px solid #464646 !important;	
    background-color: #4A4A4A !important;
    color: #999999 !important;
    font-size: 14px;
}
.ant-layout .trackdetail-btn span
{
    color: #999999 !important;
    font-size: 14px;
}
.advanceinformation-table thead th
{
    background-color: #383838 !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5) !important;
}
.advanceinformation-table thead th:hover
{
    background-color: #383838 !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5) !important;
}
.all-table-head thead th
{
    background-color: #383838 !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5) !important;
}
.all-table-head thead th:hover
{
    background-color: #383838 !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5) !important;
}
.vocal-pack-table thead th
{
    background-color: #383838 !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5) !important;
}
.vocal-pack-table thead th:hover
{
    background-color: #383838 !important;
    box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.5) !important;
}
.label-track-table tbody td
{
    padding: 0px !important;
}
.file-status-btn
{
    border-bottom: 1px solid #464646;
}
.file-status-btn button
{
    float: none;
    margin: 0px;
    padding: 0px;
    height: auto;
}
.trackfile-status-btn
{
    border-bottom: 1px solid #464646;
    padding-bottom: 9px;
}
.status-status-btn 
{
    border-bottom: 1px solid #464646;
    padding-bottom: 9px;
}
.medal-table .ant-table-pagination
{
    display: none;
}
.orderdetail-table .ant-table-pagination
{
    display: none;
}
.ant-menu-submenu-popup
{
    background: transparent !important;
}
.ant-breadcrumb
{
    background-color: #0E0E0E;
    padding: 15px 30px !important;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
}
.labeldetail-row
{
    margin-top: 25px;
}
.audition-row
{
    margin-top: 60px;
}
.ant-breadcrumb-link
{
    color: white !important;
    font-size: 18px !important;
    font-weight: bold !important;
}
.ant-breadcrumb-link a
{
    color: #c0bdd0 !important;
    font-size: 18px !important;
    font-weight: bold !important;
}
.active
{
    border-left: 2px solid green !important;
}
.original-breadcrumb
{
    display: none;
}
.ant-spin-container.ant-spin-blur
{
    background: transparent !important;
    overflow: inherit !important;
    opacity: 10 !important;
}
.ant-spin-dot-item
{
    background-color: #00D182 !important;
}
.ant-spin-dot.ant-spin-dot-spin
{
    margin: 0px !important;
}
.disable-reason-trackdetail .ant-table-pagination
{
  display: block !important;
}
.disable-reason-trackdetail table tbody tr td{
    border-bottom: 1px solid #e8e8e8 !important;   
}
.disable-reason-trackdetail .ant-pagination-item {
  background-color: transparent !important;
  color: black !important;  
}
.disable-reason-trackdetail .ant-pagination-item-link {
  background-color: transparent !important;
  color: black !important;  
}
.ant-table-row-expand-icon.ant-table-row-collapsed
{
    background: transparent !important;
    height: auto !important;
}
.ant-table-row-expand-icon.ant-table-row-expanded
{
    background: transparent !important;
    height: auto !important;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused
{
  color: white !important;
}
.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused p
{
    color: white !important;
}
.cursor-pointer td
{
    cursor: pointer;
}
.ant-table-fixed thead th span
{
    color: #777777 !important;
    font-size: 14px;
    font-weight: 600;
}
.edit-order-field label
{
    color: white !important;
}
.edit-order-field .ant-form-item
{
    width: 49% !important;
    float: left !important;
    margin-left: 10px !important;
}
.audition-trackfile-table table td
{
    padding: 0px !important;
}
.audition-trackfile-table .btn-trackfiles
{
    background: transparent !important;
    border: none !important;
}
.genre-select .ant-select-selection-selected-value
{
    color: white !important;
    margin-top: 10px !important;
}
.label-field label
{
    color: white !important;
}
.label-field input
{
    margin: 0px !important;
}
.label-field textarea
{
    margin: 0px !important;
}
.label-field .ant-select-selection
{
    margin: 0px !important;
    border: 1px solid #464646 !important;
}
.labeldetail-track-table table td
{
   padding: 0px !important;
}
.labeldetail-track-table .status-btn
{
   border-bottom: 1px solid #464646 !important;
}
.labeldetail-track-table .status-btn .page-btn:focus
{
    background: transparent !important;
    border: none !important;
}
.edit-selectfield .ant-select-selection-selected-value
{
    color: white !important;
}
.auditiondetail-table .ant-table-pagination
{
    display: none;
}
table tbody tr td{
    border-bottom: 1px solid #464646 !important;
}
.card h4
{
  text-transform: uppercase;
}
table thead th
{
  text-transform: capitalize;
}
.labeldisablereason .ant-table-pagination
{
    display: block !important;
}
.labeldisablereason .ant-table-pagination .ant-pagination-item
{
   background: transparent !important;
   color: black !important;
}
.labeldisablereason .ant-table-pagination .ant-pagination-item-link
{
   background: transparent !important;
   color: black !important;
}
.ant-layout-header .d-inline-block.mr-4
{
   display: none !important;
}
.medal-table .ant-table-row-selected td
{
   color: white !important;
}
.offset-label-labeldetail label
{
    color: white !important;
}
.download-btn-labeldetail
{
    float: right;
    justify-content: flex-end;
    margin-right: 0px;
}
.label-track-img img 
{
    height: 48px !important;
    width: 55px !important;
    border-radius: 4px;
}
.djmonitor-spin .ant-spin-dot.ant-spin-dot-spin
{
    left: 0% !important;
}
.djmonitor-spin1 .ant-spin-dot.ant-spin-dot-spin
{
  top: 20% !important;
  left: -15% !important;
  display: inline-block;
}
.ant-spin-container::after
{
    background: transparent !important;
}
.ant-modal-mask
{
    background: transparent !important;
}
.trackdetail-label-img
{
    width: 75px !important;
    height: 74px !important;
}
.cursor-pointer table tr:hover td
{
    background-color: #222222 !important;
}
.admincomment-trackdetail label
{
    color: white !important;
}
.admincomment-trackdetail label::after
{
    display: none !important;
}
.edit-icon-trackdetail
{
    cursor: pointer;
    color: #00D182;
    font-size: 18px;
    
}
body .ant-back-top-content
{
    background: black !important;
}
body .ant-back-top-content:hover
{
    background: black !important;
}
.ant-checkbox-indeterminate .ant-checkbox-inner::after
{
    background-color: #00D182 !important;
}
.paypal-table-orderdetail tbody tr td
{
    word-break: break-all;
}
.labelinformation-img
{
    height: 50px;
}
.edit-trackdetail-modal{
    width: 800px !important;
}
.track-detail-spin .ant-select
{
    width: 85% !important;
}
.track-detail-spin .spiner
{

    font-size: 20px;
    color: gray;
    
}
.track-detail-spin .spiner1
{

    font-size: 20px;
    color: gray;
    
}
.track-detail-spin .ant-select-selection__rendered .ant-select-selection-selected-value
{
    color: white !important;
}
.track-detail-spin1 .ant-select-selection__rendered .ant-select-selection-selected-value
{
    color: white !important;
}
.audition-formitem
{
    width: 37%;
    float: left;
    margin-right: 10px !important;
}
.audition-formitem .ant-select-selection__placeholder
{
    display: block !important;
    text-align: center;
    color: white !important;
}
.audition-formitem .ant-select-selection
{
    border: 1px solid #777777 !important;
}
.audition-formitem .ant-select-selection i
{
    color: #777777 !important;
}
.track-formitem .ant-select-selection
{
    border: 1px solid #777777 !important;
}
.track-formitem .ant-select-selection i
{
    color: #777777 !important;
}
.audition-formitem .ant-form-item-control.has-success .ant-select-selection__placeholder
{
  display: none !important;
}
.track-formitem .ant-select.ant-select.enabled .ant-select-selection__placeholder
{
    display: block !important;
    text-align: center;
    color: white !important;
}
.audition-formitem .ant-form-item-control .ant-select.ant-select.enabled.ant-select-focused .ant-select-selection__placeholder
{
  display: none !important;
}


.track-formitem .ant-form-item-control.has-success .ant-select-selection__placeholder
{
  display: none !important;
}
.audition-formitem1
{
    width: 20%;
    float: right;
}
.track-formitem
{
    width: 20%;
    float: left;
    margin-right: 10px !important;
}
.spiner .ant-spin-dot.ant-spin-dot-spin
{
    display: none !important;
}
.spiner1 .ant-spin-dot.ant-spin-dot-spin
{
    display: none !important;
}
.spiner {
   
    top: 5px !important;
    left: 37px !important;
    width: auto !important;
    height: auto !important;
}
.spiner1 {
   
    top: 5px !important;
    left: 37px !important;
    width: auto !important;
    height: auto !important;
}
.track-formitem1
{
    width: 11%;
    margin: 0px !important;
    float: right;
}
.genre-img
{
    height: 48px !important;
    width: 55px !important;
    border-radius: 4px;
}
.accountuser-table .ant-pagination
{
    display: none;
}
.labelinformation-img
{
    height: 70px;
}
.order-formitem
{
    width: 70%;
    float: left;
    margin-right: 10px !important;
}
.order-formitem1
{
    width: 20%;
    float: right;
}
.order-formitem1 .spiner {
    top: 5px !important;
    left: -11px !important;
    width: auto !important;
    height: auto !important;
}
.action-faq .ant-select-selection-selected-value
{
    color: white !important;
}
.demo-file-tracks tr td
{
width: 100% !important;
float: left;
border-bottom: none !important;
}
.play.button
{
    float: left;
    margin-top: 11px;
}
.react-waves-custom
{
    margin: 0px 0px 0px 50px !important;
    padding: 0px !important;
}
// .react-waves-custom canvas
// {
//     width: 100% !important;
// }
.demo-file-tracks .status-btn
{
    border-bottom: 1px solid #464646 !important;
    padding-bottom: 10px;
}
.comingsoon1{
    transform: translateY(1000%);
    position: relative;
    font-family: sans-serif;
    text-transform: uppercase;
    font-size: 2em;
    letter-spacing: 4px;
    overflow: hidden;
    /* background: linear-gradient(90deg, #000, #fff, #000); */
    background-repeat: no-repeat;
    background-size: 80%;
    animation: animate 3s linear infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: rgba(255, 255, 255, 0);
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: #00D182;
}
.approve-loader .ant-spin-dot.ant-spin-dot-spin
{
    left: 0% !important;
}
.djmonitor-spin
{
    left: -197% !important;
}
.disabletrack-modal
{
    width: 700px !important;
}
.hotpick-spiner .ant-spin-dot.ant-spin-dot-spin
{
  left: 0% !important;
}
#audition-form label{
    color: white !important;
}
#audition-form span {
    color:#777 !important;
}
#audition-form .ant-form-item-control {
    line-height:inherit  !important;
}
@media screen and (max-width: 480px) and (min-width: 320px)
{
    .track-edit-modal .ant-form-item 
{
    width: 100%;
    margin-left: 0px;
    float: none;
}
.tag-formitem .ant-form-item 
{
    width: 100% !important;
}
  .forgot-head a img
  {
    position: relative !important;
    left: 0;
    top: 0;
 
  }
  .forgot-head a
  {
      border-bottom: none !important;
  }
  .add-genre-card {
    padding-left: 20px;
    padding-right: 20px;
  }
  .add-genre-card .icmn-cross 
  {
    transform: translate(-26px, -9px);
  }
  .toggle
  {
      width: 100% !important;
      float: none !important;
  }
  .ant-spin-nested-loading > div > .ant-spin {
    top: 5px !important;
    left: -41px !important;
    width: auto !important;
    height: auto !important;
}
.track-formitem {
    width: 100%;
}
.ant-pagination-item {
  
    min-width: auto !important;
    height: auto !important;
    line-height: inherit !important;
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next
{
    min-width: auto !important;
    height: auto !important;
    line-height: inherit !important;
}
.audition-formitem {
    width: 100%;
}
.order-formitem {
    width: 100%;
}
}
@media screen and (max-width: 870px) and (min-width: 576px)
{
    .add-genre-card {
        padding-left: 20px;
        padding-right: 20px;
    }
    .add-genre-card .icmn-cross {
        transform: translate(-13px, -32px);
    }
    .toggle
    {
        width: 100% !important;
        float: none !important;
    }
    .ant-spin-nested-loading > div > .ant-spin {

        left: -2px !important;
      
    }
    .track-formitem {
        width: 100%;
    }
    .audition-formitem {
        width: 100%;
    }
}
@media screen and (max-width: 1199px) and (min-width: 930px)
{
    .toggle
    {
        width: 100% !important;
        float: none !important;
    }
    .add-genre-card .genre-form
    {
        padding: 0px;
    }
    .add-genre-card h1
    {
        font-size: 25px;
    }
    .add-genre-card p
    {
        font-size: 12px;
    }
    .add-genre-card .icmn-cross 
    {
        transform: translate(-42px, -11px);
    }
}

@media screen and (max-width: 1680px) and (min-width: 1200px)
{
    .add-genre-card .icmn-cross 
    {
        transform: translate(-61px, -44px);
    }
    // .ant-spin-nested-loading > div > .ant-spin {
    //     top: 5px !important;
    //     left: -5px !important;
    //     width: auto !important;
    //     height: auto !important;
    // }
  .spiner {
        top: 5px !important;
        left: -5px !important;
        width: auto !important;
        height: auto !important;
    }
    .spiner1 
    {
        left: -17px !important;
    }
    .order-formitem1 .spiner {
       
        left: -26px !important;
    }
}
.cke_inner{
    background: transparent !important;
}
.cke_inner body {
    background: transparent !important;
    color: white !important;
}
.cke_wysiwyg_frame body {
    background: black !important;
    color: white !important;
}
.ant-input-search-icon{
    color:inherit !important;
}
.ant-input-search input{
color: white !important;
}
.ck-dropdown__panel .ck-list{
    background: #777777 !important;
}
.ql-showHtml:after {
    content: "[source]";
  }
  #trackdetails{
      width: 50% !important;
  }
//   .ant-table-expanded-row.ant-table-expanded-row-level-1{
//       display: contents !important;
//   }
//   body .ant-select-dropdown{
//       position:fixed;
//   }
// .ckssss{
//     background: transparent !important;
//      color: white !important;
// }

@media  screen and (min-width : "992px") and (max-width : "1150px")
{
    
    #trackdetails {
        width:100% !important;
    }
}
@media  screen and (min-width : "1151px") and (max-width : "1600px")
{
    
    #trackdetails {
        width:80% !important;
    }
}
.disapprove-button span{
    color: red !important;
}
.ant-table-fixed thead th span button span {
    color: white !important;
}
#audition-form .page-btn span{
    color: white !important;
}
#facebookinvitedat div i {
    color: white !important;
}
.Labeldetailclass{
    position: absolute !important;
    margin-top: -62px !important;
    margin-left: 25px !important;
}
#id .ant-select-selection-selected-value{
    margin-top: 10px !important;
}
.tracks-search #search{
    height: 80% !important;
}
.pointer{
    cursor: pointer !important;
}
.ant-calendar,.ant-calendar-time-picker-inner,.ant-calendar-picker-input.ant-input,.ant-calendar-ok-btn.ant-calendar-ok-btn-disabled {
    background-color: #313131 !important;
    color: white !important;
  }
  .ant-calendar-picker:hover .ant-calendar-picker-input:not(.ant-input-disabled) {
     border-color: #313131;
}
  .ant-calendar-body table tr, .ant-calendar-body table td div  {
    /* background-color: #222 !important; */
    color: white !important;
  }
  .ant-calendar-time-picker-select li:hover {
    background: black;
}
  .ant-calendar-date:hover {
    background: black !important;
}
.ant-calendar-time-picker-select-option-selected,.ant-calendar-time-picker-select li:hover,.ant-calendar-selected-day .ant-calendar-date{
    background: black !important;
}
.ant-calendar-range .ant-calendar-in-range-cell::before,.ant-calendar-year-panel-year:hover,
.ant-calendar-month-panel-month:hover,.ant-calendar-decade-panel-decade:hover {
  background: black !important;
}
	.ant-calendar-date-input-wrap input{
    background-color: #222 !important;
    color: white !important;
  }
.ant-calendar-date-panel .ant-calendar-range-middle {
  color: white !important;
}
.ant-calendar-today .ant-calendar-date {
  border-color: #00D182 !important;
}

.ant-calendar-range .ant-calendar-selected-start-date .ant-calendar-date, .ant-calendar-range .ant-calendar-selected-end-date .ant-calendar-date,
.ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade
, .ant-calendar-decade-panel-selected-cell .ant-calendar-decade-panel-decade:hover,.ant-calendar-year-panel-selected-cell .ant-calendar-year-panel-year
,.ant-calendar-month-panel-selected-cell .ant-calendar-month-panel-month {
  /* color: #fff; */
  background: #00D182 !important;
  /* border: 1px solid transparent; */
}
.ant-calendar-month-panel-cell-disabled .ant-calendar-month-panel-month{
  background: #222 !important;
}
.ant-calendar-decade-panel ,.ant-calendar-month-panel ,.ant-calendar-year-panel{
  background: #222 !important;
}
.ant-calendar-picker .ant-calendar-picker-input{
    height: auto !important;
}
.ant-select-search__field{
    color: white !important;
}
li.ant-select-selection__choice {
    background: #313131 !important;
    color: white !important;

}
.underlined-link {
    color :white;
    text-decoration: underline;
}
.line-through {
    opacity: 0.5;
    text-decoration: line-through;
    text-decoration-color: white;
}
.greyed-out {
    opacity: 0.6;
}
.newdemos table {
    font-size: 1.2em;
}
.newdemos td {
    white-space: pre;
    font-size: 1.2em;
}
.newdemos input[type=radio] {
    font-size: 1.5em;

}
.newdemos label {
    margin-bottom: 10px;
}
.newdemos label.disabled {
    opacity: 0.5;
}